<template>
    <div>
        <el-form :model="form" ref="form" label-width="auto" :inline="false" size="small">
            <el-form-item label="本轮投前估值">
                <amount v-model="form.valuation"></amount>
            </el-form-item>
            <el-form-item label="累计融资额">
                <amount v-model="form.cumulativeFinancing"></amount>
            </el-form-item>
            <el-form-item label="营业收入（2023）">
                <amount v-model="form.income2023"></amount>
            </el-form-item>
            <el-form-item label="营业利润（2023）">
                <amount v-model="form.profit2023"></amount>
            </el-form-item>
            <el-form-item label="营业收入（2024）">
                <amount v-model="form.income2024"></amount>
            </el-form-item>
            <el-form-item label="营业利润（2024）">
                <amount v-model="form.profit2024"></amount>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import amount from "./amount.vue";
export default {
    props: {
        value: {
            type: [Object, String],
            default() {},
        },
    },
    data() {
        return {
            config: {},
            form: {},
        };
    },
    components: {
        amount,
    },
    watch: {
        value: {
            immediate: true,
            handler(v) {
                this.form = v || {};
            },
        },
        form: {
            immediate: true,
            // deep: true,
            handler(v) {
                this.$emit("input", v);
            },
        },
    },
    methods: {
        async getConfig() {
            let { data } = await this.$http.get("/api/Common/getConfigDictionaries?config=investor");
            this.config = data.data;
        },
    },
    computed: {},
    mounted() {},
};
</script>

<style lang="less" scoped></style>
