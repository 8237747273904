<template>
    <div>
        <el-form :model="form" ref="form" label-width="80px" :inline="false" size="small">
            <el-form-item label="融资轮次">
                <el-select v-model="form.turn">
                    <el-option v-for="item in config.turn" :key="item.key" :label="item.value" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="融资金额" label-position="top">
                <amount v-model="form.detail"></amount>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import amount from "./amount.vue";
export default {
    props: {
        value: {
            type: [Object, String],
            default() {},
        },
    },
    data() {
        return {
            config: {},
            form: {},
        };
    },
    components: {
        amount,
    },
    watch: {
        value: {
            immediate: true,
            handler(v) {
                this.form = v || {};
            },
        },
        form: {
            immediate: true,
            // deep: true,
            handler(v) {
                this.$emit("input", v);
            },
        },
    },
    methods: {
        async getConfig() {
            let { data } = await this.$http.get("/api/Common/getConfigDictionaries?config=investor");
            this.config = data.data;
        },
    },
    computed: {},
    mounted() {
        this.getConfig();
    },
};
</script>

<style lang="less" scoped></style>
