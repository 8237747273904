<template>
    <div class="project_check_list">
        <el-card>
            <nav class="input" style="width: auto">
                <el-input v-model="search.keyword" size="medium" placeholder="请输入项目名称" />
            </nav>
            <nav class="input" style="width: 150px">
                <el-button size="small" @click="reset">重置</el-button>
                <el-button type="primary" size="small" @click="$refs.table.reload()">{{ "搜索" }}</el-button>
            </nav>
            <nav class="input" style="width: auto">
                <router-link :to="{ path: '/project_list_add' }">
                    <el-button type="primary" size="small">{{ "新建项目" }}</el-button>
                </router-link>
            </nav>
        </el-card>
        <el-card style="margin-top: 10px">
            <z-table ref="table" v-model="list" @query="queryList" fullscreenLoading>
                <el-table :data="list" style="width: 100%" border stripe :header-cell-style="rowClass">
                    <el-table-column prop="id" label="ID"> </el-table-column>
                    <el-table-column prop="name" label="项目名称"></el-table-column>
                    <el-table-column prop="logo" label="LOGO">
                        <template #default="{row}">
                            <p-image :src="row.logo"></p-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc" label="项目简介" :show-overflow-tooltip="true" width="220">
                    </el-table-column>
                    <el-table-column prop="business" label="行业">
                        <template slot-scope="{ row }">
                            <div class="tag" v-for="(item, index) in row.business" :key="index">
                                <el-tag size="small">{{ item }}</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="city" label="省市">
                        <template slot-scope="{ row }">
                            <div class="view-city">
                                <span v-if="row.province">{{ row.province }}</span>
                                <span v-if="row.city">{{ row.city }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="people" label="规模"> </el-table-column>
                    <el-table-column prop="user" label="项目负责人">
                        <template #default="{row:{user}}">
                            <div>
                                {{ user.userRealName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="状态">
                        <template slot-scope="{ row }">
                            <el-tag size="small" v-if="row.hide == 0">显示</el-tag>
                            <el-tag size="small" type="info" v-if="row.hide == 1">隐藏</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="recommend" label="精选">
                        <template slot-scope="{ row }">
                            <el-tag size="small" v-if="row.recommend == 1">
                                精选
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间"> </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <router-link
                                :to="{ name: 'project_list_add', query: { id: row.id } }"
                                style="margin-right: 5px;"
                            >
                                <el-button type="text" icon="el-icon-edit" size="mini">编辑</el-button>
                            </router-link>
                            <el-button
                                @click="setProjectHide(row)"
                                type="text"
                                icon="el-icon-s-check"
                                style="color: red;"
                                size="mini"
                                >{{ row.hide ? "上架" : "下架" }}</el-button
                            >
                            <el-button
                                style="color: blue;"
                                @click="onSelect(row)"
                                type="text"
                                icon="el-icon-share"
                                size="mini"
                            >
                                {{ row.recommend ? "取消精选" : "精选" }}
                            </el-button>
                            <el-button
                                type="text"
                                icon="el-icon-upload"
                                style="color: teal;"
                                size="mini"
                                @click="toSet({ type: 'bp', title: '上传BP' }, row)"
                            >
                                上传BP
                            </el-button>
                            <el-button
                                type="text"
                                icon="el-icon-video-camera"
                                style="color: brown;"
                                size="mini"
                                @click="toSet({ type: 'video', title: '设置亮点' }, row)"
                            >
                                设置亮点
                            </el-button>
                            <el-button
                                type="text"
                                icon="el-icon-s-platform"
                                style="color: darkgray;"
                                size="mini"
                                @click="toSet({ type: 'financingCurrent', title: '设置我要融资' }, row)"
                            >
                                设置我要融资
                            </el-button>
                            <el-button
                                type="text"
                                icon="el-icon-picture-outline-round"
                                style="color: yellowgreen;"
                                size="mini"
                                @click="toSet({ type: 'financingHistory', title: '设置融资历史' }, row)"
                            >
                                设置融资历史
                            </el-button>
                            <el-button
                                type="text"
                                icon="el-icon-s-order"
                                style="color: darkgoldenrod;"
                                size="mini"
                                @click="toSet({ type: 'financingMessage', title: '设置融资信息' }, row)"
                            >
                                设置融资信息
                            </el-button>
                            <el-button
                                type="text"
                                icon="el-icon-s-finance"
                                style="color: darkorange;"
                                size="mini"
                                @click="toSetGs(row)"
                            >
                                设置工商信息
                            </el-button>
                            <el-button
                                type="text"
                                style="color: red;"
                                icon="el-icon-delete"
                                size="mini"
                                @click="onDelete(row)"
                                >删除</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
            </z-table>
        </el-card>
        <pop ref="pop" :title="setDetail.title" @toAudit="toAudit">
            <template v-if="setDetail.type == 'bp'">
                <up-file type="bp" :value.sync="setDetail.bp" :colUrl="colUrl"></up-file>
            </template>
            <template v-if="setDetail.type == 'video'">
                <up-file type="video" :value.sync="setDetail.video" :colUrl="colUrl"></up-file>
                <video
                    controls="true"
                    style="width: 100%;margin-top: 20px;"
                    v-show="setDetail.video"
                    :src="setDetail.video"
                ></video>
            </template>
            <template v-if="setDetail.type == 'financingCurrent'">
                <financing-current v-model="setDetail.financingCurrent"></financing-current>
            </template>
            <template v-if="setDetail.type == 'financingHistory'">
                <financing-history v-model="setDetail.financingHistory"></financing-history>
            </template>
            <template v-if="setDetail.type == 'financingMessage'">
                <financing-message v-model="setDetail.financingMessage"></financing-message>
            </template>
        </pop>
    </div>
</template>

<script>
import list from "/src/mixin/list.js";
import financingCurrent from "./financingCurrent.vue";
import financingHistory from "./financingHistory.vue";
import financingMessage from "./financingMessage.vue";
export default {
    mixins: [list],
    data() {
        return {
            search: {
                keyword: "",
            },
            setDetail: {},
            colUrl: "https://finance.yfchuhai.cn/api/Common/upload",
        };
    },
    components: {
        financingCurrent,
        financingHistory,
        financingMessage,
    },
    activated() {
        // console.log(1);
    },
    methods: {
        getlist() {
            this.$refs.table.refresh();
        },
        toSet(val, row) {
            this.setDetail = { ...row, ...val };
            this.$refs.pop.show = true;
        },
        toSetGs(row) {
            this.$router.push({
                name: "industrialIn_iformation_edit",
                query: {
                    projectId: row.id,
                    // businessInfo: JSON.stringify(row.businessInfo),
                },
            });
        },
        async queryList(val) {
            let { data: res } = await this.$http.get("/admin/Finance/getProjectList", {
                params: { ...val, ...this.search },
            });
            if (res.errorCode == 200) {
                // this.$message.success(res.message);
                this.$refs.table.complete(res.data);
            }
        },

        async toAudit() {
            let reqData = {};
            reqData["id"] = this.setDetail.id;
            reqData[this.setDetail.type] = this.setDetail[this.setDetail.type];
            if (typeof reqData[this.setDetail.type] == "object") {
                if (this.setDetail.type == "financingHistory") {
                    reqData.financingHistory = reqData.financingHistory.filter(
                        (item) => item.time && item.turn && item.detail && item.company
                    );
                }
                reqData[this.setDetail.type] = JSON.stringify(reqData[this.setDetail.type]);
            }
            let { data: res } = await this.$http.post("/admin/Finance/setProject", reqData);
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$refs.pop.show = false;
                this.getlist();
            }
        },
        onEdit(row) {
            this.$router.push({ name: "sms_template_list_add", query: { id: row.id } });
        },
        async onSelect(row) {
            let { data: res } = await this.$http.post("/admin/Finance/projectTop", {
                id: row.id,
                recommend: row.recommend ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$refs.table.refresh();
            }
        },
        async setProjectHide(row) {
            let { data: res } = await this.$http.post("/admin/Finance/setProjectHide", {
                id: row.id,
                hide: row.hide ? 0 : 1,
            });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.$refs.table.refresh();
            }
        },
        async auditNotThrough(row) {
            var res = await this.$prompt("请输入不通过原因", "提示");
            var { data: res } = await this.$http.post("/admin/Finance/projectCheck", {
                id: row.id,
                status: 2,
                failReason: res.value,
            });
            this.$refs.table.refresh();
        },
        async onDelete(row) {
            await this.$confirm("此操作将永久删除该, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            });
            let { data: res } = await this.$http.post("/admin/Finance/delProject", { id: row.id });
            if (res.errorCode == 200) {
                this.$message.success(res.message);
                this.getlist();
            }
        },
        Ftag(val) {
            if (val == 0) {
                return "待审核";
            }
            if (val == 1) {
                return "审核通过";
            }
            if (val == 2) {
                return "审核不通过";
            }
            return "";
        },
    },
    mounted() {
        sessionStorage.setItem("action", "project");
    },
};
</script>

<style lang="less" scoped>
.tag {
    display: inline-block;
    margin: 5px;
}
</style>
