<template>
    <div>
        <div class="view-init" v-for="(item, index) in formList" :key="index">
            <Hform :config="config" v-model="formList[index]"></Hform>
        </div>
        <div class="flex-col">
            <el-button type="primary" @click="toAdd">继续添加</el-button>
        </div>
    </div>
</template>

<script>
import Hform from "./form";
export default {
    props: {
        value: {
            type: [Object, String],
            default() {},
        },
    },
    data() {
        return {
            config: {},
            formList: [{}],
        };
    },
    components: {
        Hform,
    },
    watch: {
        value: {
            immediate: true,
            handler(v) {
                this.formList = v || [{}];
                if(this.formList.length == 0){
                    this.formList.push({})
                }
            },
        },
        formList: {
            immediate: true,
            // deep: true,
            handler(v) {
                console.log(v);
                this.$emit("input", v);
            },
        },
    },
    methods: {
        async getConfig() {
            let { data } = await this.$http.get("/api/Common/getConfigDictionaries?config=investor");
            this.config = data.data;
        },
        async toAdd() {
            this.formList.push({});
        },
    },
    computed: {},
    mounted() {
        this.getConfig();
    },
};
</script>

<style lang="less" scoped>
.view-init {
    margin-bottom: 20px;
    &:not(:last-of-type) {
        &::after {
            content: "";
            display: block;
            border-bottom: 4px solid #f7f9fe;
            width: 100%;
        }
    }
}
</style>
