<template>
    <div style="padding: 0;">
        <el-collapse v-model="activeNames">
            <el-collapse-item :title="fiterValue" name="1">
                <el-form :model="form" label-width="80px" size="small">
                    <el-form-item label="钱币">
                        <el-radio
                            v-for="(item, index) in currency"
                            :key="index"
                            v-model="form.coin"
                            :label="item.name"
                        ></el-radio>
                    </el-form-item>
                    <el-form-item label="金额">
                        <el-input size="small" type="number" v-model="form.amount"></el-input>
                    </el-form-item>
                    <el-form-item label="单位">
                        <el-radio
                            v-for="(item, index) in unit"
                            :key="index"
                            v-model="form.unit"
                            :label="item.name"
                        ></el-radio>
                    </el-form-item>
                </el-form>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [Object],
            default() {
                return {};
            },
        },
    },

    watch: {
        value: {
            handler(val) {
                if (!this.value) return;
                if (!this.value.amount) return;
                this.form = val;
            },
            immediate: true,
        },
        form: {
            deep: true,
            handler(v) {
                this.$emit("input", v);
            },
        },
    },
    data() {
        return {
            activeNames: [],
            currency: [
                {
                    name: "人民币",
                },
                {
                    name: "美元",
                },
            ],
            unit: [
                {
                    name: "万",
                },
                {
                    name: "亿",
                },
            ],
            form: {
                coin: "人民币",
                unit: "万",
                amount: "",
            },
        };
    },
    methods: {
        formJust() {
            return new Promise((resolve, reject) => {
                if (this.value && !this.form.amount) {
                    return this.$u.toast("  请填写金额");
                }
                resolve();
            });
        },
        coinText(val) {
            if (val == "人民币") {
                return "￥";
            }
            return "$";
        },
    },
    computed: {
        fiterValue() {
            if (!this.form) {
                return "请点击填写金额";
            }
            if (!this.form.amount) return `${this.coinText(this.form.coin)}${0}${this.form.unit || ""}`;
            return `${this.coinText(this.form.coin)}${this.form.amount}${this.form.unit}`;
        },
    },
};
</script>

<style lang="less" scoped></style>
