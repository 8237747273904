<template>
    <div>
        <el-form :model="form" ref="form" label-width="80px" :inline="false" size="small">
            <el-form-item label="融资时间">
                <el-date-picker
                    value-format="yyyy-MM"
                    format="yyyy-MM"
                    v-model="form.time"
                    type="month"
                    placeholder="选择月"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="融资轮次">
                <el-select v-model="form.turn">
                    <el-option v-for="item in config.turn" :key="item.key" :label="item.value" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="融资金额" label-position="top">
                <amount v-model="form.detail"></amount>
            </el-form-item>
            <el-form-item label="投资公司">
                <el-input v-model="form.company" placeholder="请输入"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import amount from "./amount.vue";
export default {
    props: {
        config: {
            type: Object,
            default() {
                return {};
            },
        },
        value: {
            type: [Object],
            default() {
                return {};
            },
        },
    },
    watch: {
        value: {
            immediate: true,
            handler(v) {
                this.form = v || {};
            },
        },
        form: {
            immediate: true,
            // deep: true,
            handler(v) {
                this.$emit("input", v);
            },
        },
    },
    data() {
        return {
            form: {},
        };
    },
    components: {
        amount,
    },
};
</script>

<style lang="less" scoped></style>
